import React, { useEffect, useState } from 'react';
import './product.css';
import axios from 'axios';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useNavigate,useLocation } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { Toaster, toast } from 'sonner'
import NewSlider from '../Testimonials/NewSlider';

export function MainProducts({ user_id }) {

    const location=useLocation();
    const cat_id=location.state?.cat_id||[];
    // console.log("Category ID : ",cat_id)
    const [productInfo, setProductInfo] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [count, setCount] = useState(0);
    const [name, setName] = useState('');

    const [totalPrice, setTotalPrice] = useState(0);
    const [rating, setRating] = useState(0); // Initial rating state

    const handleRatingClick = (selectedRating) => {
        setRating(selectedRating); // Update rating state when a star is clicked
    };

    const navigate = useNavigate();

    const ratingChanged = (newRating) => {
        // console.log(newRating);
    };


    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            setTotalPrice(selectedProduct.after_discount_price * (quantity - 1));
        }
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
        setTotalPrice(selectedProduct.after_discount_price * (quantity + 1));
    };


    const handlechangecount = (e) => {
        setCount(e.target.value)
    }

    useEffect(() => {
        const id=cat_id;
         // console.log("ID aayi : ",id)

         const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist/${id}`);
                setProductInfo(res.data);
                // console.log("Finally : ", productInfo);
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };
        fetchData();

        // const fetchReview = async () => {
        //     try {
        //         const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_review_data/${id}`);
        //         // setProductInfo(res.data);
        //         setRating(res.data)
        //         // console.log("Finally : ", rating);
        //     } catch (error) {
        //         // console.error("Error fetching product data:", error.message);
        //     }
        // };
        // fetchReview();
    }, []);

    const handleAddToCart = (product,after_discount_price) => {
        if (user_id > 0) {
            setSelectedProduct(product);
            // console.log("Selected Product : ",selectedProduct)
            setIsModalOpen(true);
            // console.log("Discount Price : ",after_discount_price)
            setTotalPrice(after_discount_price);
        }
        else {
            alert("Please Login first")
        }

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleSubmit = async (e, selectedProduct, total_price, count, user_id) => {
        e.preventDefault();
        // console.log("Product to cart : ", selectedProduct.product_id)
        const product_id = selectedProduct.product_id;
        // console.log("Product count : ", count, "user ID : ", user_id, total_price)

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/add_to_cart/${product_id}/${count}/${total_price}/${user_id}`,

            );
            // console.log(res)
            if (res.status == 200) {
                toast.success("Added to Cart Successfully!!")
               window.open('/','_self')
            }

        } catch (error) {
            // console.error("Error assigning subject data:", error.message);
        }

    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_cart_count/${user_id}`
                );
                // console.log("count bacchi", res);
                if (res.status === 200) {
                    // Assuming res.data is an object with a key 'count'
                    setCount({ count: res.data.count });
                    // console.log("count : ", res.data.count);
                }
            } catch (error) {
                // console.error("Error assigning subject data:", error.message);
            }
        };

        fetchData();





    }, [user_id]);

    const handlenavigate = (product_id) => {
        // console.log("Product Data  : ", product_id, user_id)
        navigate('/main_product', { state: { product_id: product_id, user_id: user_id } })
    }

    const handlesearchproduct = async (product_name) => {
        setName(product_name)
        // console.log("Ye search hone wala hai:", name);
        try {
            const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproducts_by_search/${name}`);
            setProductInfo(result.data);
            // console.log("Quant:", result.data)

        } catch (error) {
            // console.log(error)
        }


    }

    return (
        <>
            <div className="container" style={{marginTop:'2%'}}>
            <Toaster position="top-center" richColors />

                <div className="category_head mt-2">
                    <h3>Product List</h3>
                </div>

                <label for="exampleInputEmail1" className="form-label my-1">Search Product</label>
                <div className="mb-3" id="search_bar">
                    <input type="email" style={{ boxShadow: '4px 4px 4px 4px rgb(0,0,0,0.2)', height: '50px' }} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Search Product here...' onChange={(e) => handlesearchproduct(e.target.value)} />
                </div>


                <div className="product_cards mt-5">
                    <div className="apne_cards">
                        <div className="card_container" style={{height:'fit-content'}}>
                            {/* {productInfo.map((data, i) => (
                                <div className='actual_cards' key={i} >
                                    <img className='object-fit-fill' src={`http://localhost:8000/view_doc/${data.product_img}`} alt="..." />
                                    <div className="data" onClick={() => handlenavigate(data.product_id)}>
                                        <h5>{data.product_name}</h5>
                                        <p>{`(Size : ${data.product_size} )`}</p>
                                        <h5>₹{data.product_price}</h5>
                                    </div>
                                    <button className='btn btn-success w-100' onClick={() => handleAddToCart(data)}>Add to Cart</button>
                                </div>
                            ))} */}

                            {productInfo.map((data, i) => (
                                <div class="card" style={{ width: "90rem", height: 'fit-content' }}>
                                    <div class="row g-0 main_products_list"  style={{backgroundColor:'rgb(199 252 189 / 19%)'}}>
                                        <div class="col-md-4 main_product_img">
                                            <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.product_img}`} class="img-fluid rounded" alt="..." />
                                        </div>
                                        <div class="col">
                                            <div class="card-body main_products_content">
                                                <h4 class="card-title" onClick={()=>handlenavigate(data.product_id)}>{data.product_name}</h4>
                                                <p className='card-text mt-1'>Size : {data.product_size}</p>
                                                <div className="mt-0">
                                                <ReactStars
                                                    count={5}
                                                    onChange={ratingChanged}
                                                    size={24}
                                                    value={data.max_rating}
                                                    activeColor="#ffd700"
                                                    edit={false}
                                                />
                                                </div>
                                                <div className="d-flex gap-3 align-items-center align-content-center col-md-6 border-left mt-1">
                                                    <div className="d-flex flex-row align-items-center">
                                                        
                                                        <h4 className="mr-1">₹{data.after_discount_price}</h4>
                                                        <span className='"strike-text mx-2'>M.R.P</span><span className="strike-text" style={{ textDecoration: "line-through", color: 'red' }}>₹{data.product_price}</span>
                                                    </div>
                                                    {data.product_discount > 0 ? (
                                                    <h6 className="text-success">Discount : {Math.floor(data.product_discount)} %</h6>

                                                    ):(<>
                                                    </>)}
                                                </div>
                                                <div className='mt-0'>
                                                    <button className='btn btn-success btn-sm mt-2 border-rounded' style={{borderRadius:'20px'}} onClick={() => handleAddToCart(data,data.after_discount_price)}>Add to Cart</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>


                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <div className="product_detail">
                            <div className="product_modal_img">
                                <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${selectedProduct.product_img}`} alt="..." />
                            </div>

                            <div className="product_content">
                                <h2 className='mb-3'>{selectedProduct.product_name}</h2>
                                <h6 className='mb-3'>{`(Size : ${selectedProduct.product_size} )`}</h6>
                                <h5 className='mb-3'>Price : ₹ {selectedProduct.after_discount_price}</h5>
                                <InputGroup className="mb-3">
                                    <Button variant="outline-danger" onClick={decreaseQuantity}>-</Button>
                                    <FormControl
                                        aria-label="Quantity"
                                        value={quantity}
                                        readOnly
                                        style={{ textAlign: 'center' }}
                                    />
                                    <Button variant="outline-success" onClick={increaseQuantity}>+</Button>
                                </InputGroup>
                                <h5 className='mb-3'>Total Price : ₹{totalPrice}/-</h5>

                                <br />
                                <button className='btn btn-success w-100' onClick={(e) => handleSubmit(e, selectedProduct, totalPrice, quantity, user_id)}>Add to Cart</button>
                            </div>
                        </div>


                    </div>
                </div>
            )}
            
            
        </>
    )
}
