import React from 'react'
import './more.css'



const MoreInfo = () => {
    return (
        <>

            <div className="mx-3">
                <section style={{backgroundColor:'#ffc0cb4f',width:'98%'}} class="elementor-section elementor-top-section elementor-element elementor-element-2106dbd1 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default" data-id="2106dbd1" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;_ha_eqh_enable&quot;:false}">
                    <div class="elementor-container d-flex flex-wrap elementor-column-gap-no">
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-405325a5" data-id="405325a5" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-widget-wrap">
                            <img src='https://www.medisana.com/healthblog/app/uploads/2021/10/shutterstock_1230755437_neu-1-700x0-c-default.png' id="main_img"/>
                        </div>
                        </div>
                        
                        <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-79cc6fcd" data-id="79cc6fcd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                            <div class=" elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7d368dbc elementor-widget elementor-widget-heading" data-id="7d368dbc" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container mt-3">
                                        <h2 class="elementor-heading-title elementor-size-default text-center">Why Organicko?
                                        </h2>
                                    </div>
                                </div>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-137a6be0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="137a6be0" data-element_type="section" data-settings="{&quot;_ha_eqh_enable&quot;:false}">
                                    <div class=" d-flex flex-wrap elementor-container elementor-column-gap-default">
                                        <div class=" d-flex flex-wrap elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2e717ff6"  style={{width:'20rem'}} data-id="2e717ff6" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-222b5f05 elementor-widget elementor-widget-image" data-id="222b5f05" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/onlybest-90.png" class="attachment-large size-large wp-image-16551" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/onlybest-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/onlybest-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-13f203d8 elementor-widget elementor-widget-heading" data-id="13f203d8" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 className="elementor-heading-title elementor-size-default text-center" style={{ fontSize: '22px' }}>Only The Best</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-46d37351 elementor-widget elementor-widget-text-editor" data-id="46d37351" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container text-center">

                                                        Selected ingredients, unrefined, chemical-free hygienic processes to provide genuine products.

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1dc8b0a5"  style={{width:'20rem'}} data-id="1dc8b0a5" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-2702e4b6 elementor-widget elementor-widget-image" data-id="2702e4b6" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/traditional-90.png" class="attachment-large size-large wp-image-16552 text-center" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/traditional-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/traditional-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-6160b493 elementor-widget elementor-widget-heading" data-id="6160b493" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default text-center" style={{ fontSize: '22px' }}>Reviving Traditions
                                                        </h2>		</div>
                                                </div>
                                                <div class="elementor-element text-center elementor-element-7bb82b83 elementor-widget elementor-widget-text-editor" data-id="7bb82b83" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <div class="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium text-center"><div class="elementor-widget-container text-center"><p className='text-center'>Use of techniques like Cold Press, Stone Grinder, Mortar Pestle, Sun Drying, etc. to promote local craft.</p></div></div>						</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6e9b3266"  style={{width:'20rem'}} data-id="6e9b3266" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-491a2910 elementor-widget elementor-widget-image" data-id="491a2910" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/nutritive-90.png" class="attachment-large size-large wp-image-16553" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/nutritive-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/nutritive-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-247c78cb elementor-widget elementor-widget-heading" data-id="247c78cb" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default text-center" style={{ fontSize: '22px' }}>Higher Nutrition
                                                        </h2>		</div>
                                                </div>
                                                <div class="elementor-element elementor-element-58568409 elementor-widget elementor-widget-text-editor" data-id="58568409" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <div class="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium"><div class="eltdf-iwt-content"><p class="eltdf-iwt-text">Natural, Whole &amp; Unrefined food retain more nutrients than conventionally produced food.</p></div></div>						</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-56e6f21e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="56e6f21e" data-element_type="section" data-settings="{&quot;_ha_eqh_enable&quot;:false}">
                                    <div class=" d-flex flex-wrap elementor-container elementor-column-gap-default">
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b123569"  style={{width:'20rem'}} data-id="b123569" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-a6c50dc elementor-widget elementor-widget-image" data-id="a6c50dc" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/residue-free-90.png" class="attachment-large size-large wp-image-16554" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/residue-free-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/residue-free-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-2630c7ca elementor-widget elementor-widget-heading" data-id="2630c7ca" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <h2 class="elementor-heading-title elementor-size-default" style={{ fontSize: "22px" }}>Residue-Free
                                                        </h2>		</div>
                                                </div>
                                                <div class="elementor-element elementor-element-172f67f4 elementor-widget elementor-widget-text-editor" data-id="172f67f4" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p className='text-center'>All by-products after processing are used as livestock feed, thus leaving zero leftovers</p>						</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2203fc26" style={{width:'20rem'}} data-id="2203fc26" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-2cf7c399 elementor-widget elementor-widget-image" data-id="2cf7c399" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/and-only-best-90.png" class="attachment-large size-large wp-image-16555" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/and-only-best-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/and-only-best-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-6c8fa21 elementor-widget elementor-widget-heading" data-id="6c8fa21" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default text-center" style={{ fontSize: "22px" }}>Sustainability
                                                        </h2>		</div>
                                                </div>
                                                <div class="elementor-element elementor-element-5652049e elementor-widget elementor-widget-text-editor" data-id="5652049e" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p className='text-center'>Supports natural, organic agricultural practices that serve and protect Mother Nature</p>						</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2a9bb206" style={{width:'20rem'}} data-id="2a9bb206" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-148658df elementor-widget elementor-widget-image" data-id="148658df" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container text-center">
                                                        <img loading="lazy" decoding="async" width="90" height="90" src="https://orgatma.com/wp-content/uploads/2022/10/nutritive-90.png" class="attachment-large size-large wp-image-16553" alt="" srcset="https://orgatma.com/wp-content/uploads/2022/10/nutritive-90.png 90w, https://orgatma.com/wp-content/uploads/2022/10/nutritive-90-50x50.png 50w" sizes="(max-width: 90px) 100vw, 90px" />															</div>
                                                </div>
                                                <div class="elementor-element elementor-element-54fce475 elementor-widget elementor-widget-heading" data-id="54fce475" data-element_type="widget" data-widget_type="heading.default">
                                                    <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default text-center" style={{ fontSize: "22px" }}>And only the Best</h2>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-2d9e6f0c elementor-widget elementor-widget-text-editor" data-id="2d9e6f0c" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <div class="eltdf-iwt clearfix eltdf-iwt-icon-top eltdf-iwt-icon-medium"><div class="eltdf-iwt-content"><p class="eltdf-iwt-text text-center">Our endeavor is to create not just the best products but the best lifestyle.</p></div></div>						</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default MoreInfo
