import React, { useEffect, useState } from 'react';
import './product.css';
import axios from 'axios';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";


export function ProductList({ user_id }) {

    const [productInfo, setProductInfo] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [count, setCount] = useState(0);
    const [reviews,setReviews]=useState(0);

    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            setTotalPrice(selectedProduct.after_discount_price * (quantity - 1));
        }
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
        setTotalPrice(selectedProduct.after_discount_price * (quantity + 1));
    };


    const handlechangecount = (e) => {
        setCount(e.target.value)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist`);
                if (res.status === 200) {
                    setProductInfo(res.data);
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        const fetchReview = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_reviews`);
                if (res.status === 200) {
                    setReviews(res.data);
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };


        // 

        fetchData();
        fetchReview();

    }, []);

    const handleAddToCart = (product) => {
        if (user_id > 0) {
            setSelectedProduct(product);
            setIsModalOpen(true);
            setTotalPrice(product.after_discount_price);
        }
        else {
            alert("Please Login first")
        }

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleSubmit = async (e, selectedProduct, total_price, count, user_id) => {
        e.preventDefault();
        // console.log("Product to cart : ", selectedProduct.product_id)
        const product_id = selectedProduct.product_id;
        // console.log("Product count : ", count, "user ID : ", user_id, total_price)

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/add_to_cart/${product_id}/${count}/${total_price}/${user_id}`,

            );
            // console.log(res)
            if (res.status == 200) {
                alert("Added to Cart Successfully!")
                window.location.reload()
            }

        } catch (error) {
            // console.error("Error assigning subject data:", error.message);
        }

    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_cart_count/${user_id}`
                );
                // console.log("count bacchi", res);
                if (res.status === 200) {
                    // Assuming res.data is an object with a key 'count'
                    setCount({ count: res.data.count });
                    // console.log("count : ", res.data.count);
                }
            } catch (error) {
                // console.error("Error assigning subject data:", error.message);
            }
        };

        fetchData();





    }, [user_id]);

    const handlenavigate = (product_id) => {
        // console.log("Product Data  : ", product_id, user_id)
        navigate('/main_product', { state: { product_id: product_id, user_id: user_id } })
    }

    return (
        <>
            <div className="container">
                <div className="category_head mb-0">
                    <h3>Featured Products</h3>
                    <p>Explore Our Popular Products...</p>
                </div>
                <div className="product_cards mt-0">
                    <div className="apne_cards">
                        <div className="card_container">
                            {productInfo.map((data, i) => (
                                <div className='actual_cards' key={i} >
                                    <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.product_img}`} alt="..." />
                                    <div className="data" onClick={() => handlenavigate(data.product_id)}>
                                        <h5>{data.product_name}</h5>
                                        <p>{`(Size : ${data.product_size} )`}</p>
                                        
                                        <div className='d-flex justify-content-center flex-column align-items-center'>
                                        
                                        <ReactStars
                                                        count={5}
                                                       
                                                        size={20}
                                                        value={4}
                                                        activeColor="#ffd700"
                                                        className='mt-0'
                                                        edit={false}
                                                    />
                                            <h5>₹ {data.after_discount_price}/-</h5>
                                            {data.product_discount>0 ? (
                                                <div className='d-flex gap-3'>
                                                    <p style={{ textDecoration: 'line-through', color: 'red' }}>M.R.P ₹{data.product_price}</p>
                                                    <p style={{ color: 'green' }}><b>{data.product_discount}% Off</b></p>
                                                </div>
                                            ) : (
                                                <>
                                                 <div className='d-flex gap-3'>
                                                    <p>(No offers Avaliable)</p>
                                                    {/* <p style={{ color: 'green' }}><b>({data.product_discount})%</b></p> */}
                                                </div>
                                                </>
                                            )}


                                        </div>

                                    </div>
                                    <button className='btn btn-success w-100' onClick={() => handleAddToCart(data)}>Add to Cart</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <div className="product_detail">
                            <div className="product_modal_img">
                                <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${selectedProduct.product_img}`} alt="..." />
                            </div>

                            <div className="product_content">
                                <h2 className='mb-3'>{selectedProduct.product_name}</h2>
                                <h6 className='mb-3'>{`(Size : ${selectedProduct.product_size} )`}</h6>
                                <h5 className='mb-3'>Price : ₹{selectedProduct.after_discount_price}</h5>
                                <InputGroup className="mb-3 counter">
                                    <button className=' btn btn-outline-danger' onClick={decreaseQuantity}>-</button>
                                    <FormControl
                                        aria-label="Quantity"
                                        value={quantity}
                                        readOnly
                                        style={{ textAlign: 'center' }}
                                    />
                                    <button className='btn btn-outline-success'onClick={increaseQuantity}>+</button>
                                </InputGroup>
                                <h5 className='mb-3' id='total_price_modal'>Total Price : ₹ {totalPrice}/-</h5>
                                <button className='btn btn-success w-100' onClick={(e) => handleSubmit(e, selectedProduct, totalPrice, quantity, user_id)}>Add to Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
