import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'sonner';

const PhoneEntry = () => {
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userdata, setUserData] = useState([]);
    const [productinfo, setProductInfo] = useState([])

    const [formData, setFormData] = useState({
        mobilenumber: 0,
        category: '',
        size: '',
        price: '',
        quantity: '',
        discount: '',
        total_price:'',
        d_charge:''
    });

    const [totaloverallprice,setTotalOverallPrice]=useState({
        total_price:0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_category_data`);
                if (res.status === 200) {
                    setCategory(res.data);
                }
            } catch (error) {
                console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();
    }, []);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    
        if (name === 'category') {
            const id = formData.category;
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist/${id}`);
                setProductInfo(res.data);
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        } else if (name === 'product') {
            const selectedProduct = productinfo.find(product => product.product_id == value);
            if (selectedProduct) {
                setFormData(prevState => ({
                    ...prevState,
                    size: selectedProduct.product_size,
                    price: selectedProduct.product_price,
                    product: selectedProduct.product_id,
                }));
            }
        } else if (name === 'quantity' || name === 'discount' || name==='d_charge') {
            const quantity = parseFloat(value); 
            const discount = parseFloat(formData.discount);
            const price = parseFloat(formData.price);
            console.log("Product Price: ",price)
            const delivery = parseFloat(formData.d_charge);
            
            let totalPrice = quantity * price * (1 - discount / 100)+delivery;
            let total_Price = quantity * price * (1 - discount / 100);

            setTotalOverallPrice(prevState => ({
                ...prevState,
                total_price: totalPrice.toFixed(2),
            }))
         
            setFormData(prevState => ({
                ...prevState,
                total_price: total_Price.toFixed(2),
            }));
        }
    };
    
    



    useEffect(() => {
        const fetchProductInfo = async () => {
            try {
                const id = formData.category;
                console.log("ID:", id)
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist/${id}`);
                setProductInfo(res.data);
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        };

        if (formData.category !== '') {
            fetchProductInfo();
        }
    }, [formData.category]);

    const handleCategory = async (e) => {

        const { name, value } = e.target;
        console.log(name)
        setFormData({ ...formData, [name]: value });
        console.log("Categrory:", formData.category);


    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('mobilenumber', formData.mobilenumber);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('size', formData.size);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('quantity', formData.quantity);
        formDataToSend.append('discount', formData.discount);
        formDataToSend.append('total_price', formData.total_price);
        formDataToSend.append('d_charge', formData.d_charge);

    
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/confirm_order_admin`, formData);
            if (response.data.success === false) {
                toast.warning(response.data.message);
            } else if (response.data.success === true) {
                toast.success(response.data.message);
                window.open('/', '_self');
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlegetuserdata = async (e) => {
        e.preventDefault();
        if (formData.mobilenumber == 0) {
            alert("Please Enter the mobile number!!");
        } else {
            try {
                const phone = formData.mobilenumber;
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getUserInfoByPhone/${phone}`);
                
                
                if (response.status === 200) {
                    setUserData(response.data.user_data);
                }
              
            } catch (error) {
                alert("No user found!!");
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            <div className="add_product mb-2" style={{ marginTop: '5%' }}>
                <Toaster position="top-center" richColors />

                <div className="container">
                    <div className="add_product_container">
                        <div className="register_head mb-3">
                            <h2>Add User Orders</h2>
                            <p>Please provide the user Contact number...</p>
                        </div>

                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-md-12">
                                <label htmlFor="mobilenumber" className="form-label">Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="mobilenumber"
                                    name="mobilenumber"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12">
                                <button className='btn btn-danger col-md-12' onClick={(e) => handlegetuserdata(e)}>Get User Data</button>
                            </div>

                            {userdata.length > 0 && (
                                userdata.map((data, i) => (
                                    <div key={i} className='d-flex justify-content-center align-items-center'>
                                        <div className="upload-square text-center" style={{ width: '50rem' }}>
                                            <h7> <b>Name: </b>{data.firstName} {data.lastName}</h7>
                                            <br />
                                            <h7><b>Address:</b> {data.address1}, {data.address2} {data.city} {data.state} {data.pincode}</h7>
                                        </div>
                                    </div>
                                ))
                            )}

                            <div className="col-md-4">
                                <label htmlFor="category" className="form-label">Category</label>
                                <select className="form-select" aria-label="Default select example" id="category" name="category" onChange={handleChange}>
                                    <option defaultChecked={true}>--Select Category--</option>
                                    {category.map((data, i) => (
                                        <option value={data.ID} key={i}>{data.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="size" className="form-label">Product</label>
                                <select className="form-select" aria-label="Default select example" id="product" name="product" onChange={handleChange}>
                                    <option defaultChecked>--Select Product--</option>
                                    {productinfo.map((data, index) => (
                                        <option value={data.product_id} key={index}>{data.product_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="size" className="form-label">Size</label>
                                <select className="form-select" aria-label="Default select example" id="size" name="size" value={formData.size} onChange={handleChange}>
                                    <option defaultChecked>--Select Size--</option>
                                    {productinfo.map((product, index) => (
                                        product.product_id === formData.product && (
                                            <option value={product.product_size} key={index}>{product.product_size}</option>
                                        )
                                    ))}
                                </select>
                            </div>


                            <div className="col-md-4">
                                <label htmlFor="prices" className="form-label">Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="prices"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                  readOnly
                                />
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="discount" className="form-label">Delivery Charges</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="d_charge"
                                    name="d_charge"
                                    value={formData.d_charge}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="discount" className="form-label">Discount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="discount"
                                    name="discount"
                                    value={formData.discount}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="quantity" className="form-label">Quantity</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="quantity"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                />
                            </div>

                           
                            <div className="col-md-6">
                                <label htmlFor="total_price" className="form-label">Total Price</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="total_price"
                                    name="total_price"
                                    value={totaloverallprice.total_price}
                                    readOnly
                                />
                            </div>

                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" className="btn btn-success col-12" style={{ backgroundColor: '#095809' }}>Add Order</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhoneEntry;
