import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviews.css";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactStars from "react-rating-stars-component";


function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [screeWidth, setScreen] = useState(window.innerWidth);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: screeWidth>720 ? 3 : 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const fetchReviews = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_admin_review`);
        if (res.status === 200) {
          setReviews(res.data);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error.message);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="card-container">
      <div className="card" style={{ width: "100%" }}>
        <div className="service-static-body">
          <div className="category_head">
            <h3>Testimonials</h3>
          </div>
        </div>
        <div className="new" data-aos="fade-up">
          <div className="mt-20" id="width_to_change">
            <Slider {...settings}>
              {reviews.map((review) => (
                <div key={review.id} className="review_card bg-white text-black rounded-xl" style={{ height: '11rem' }}>

                  <div className="flex flex-col items-center justify-center gap-4 p-4 flex-wrap inner_review_card" style={{ height: '11rem' }}>
                    <ReactStars
                      count={5}
                      size={20}
                      value={review.rating}
                      activeColor="#ffd700"
                      className='col-md-6'
                      edit={false}
                    />

                    <p className="text-center">{review.review}</p>
                    <h6 className="text-center font-semibold">~ {review.user_name}</h6>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default Reviews;
