import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./newslide.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import axios from "axios";

function NewSlider({ product_id }) {
  const [data, setData] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get_reviews/${product_id}`
        );
        if (res.status === 200) {
          setData(res.data);
          // console.log("Main data aya :", data);
        }
      } catch (error) {
        // console.error("Error fetching product data:", error.message);
      }
    };
    fetchData();
  }, [product_id]);

  return (
    <div className="main_review_section">
    <div className="card-container" style={{ boxShadow: "0px" }}>
      <div className="category_head">
        <h3 className="head">Reviews</h3>
        <p>Reviews given by our beloved customers...</p>
      </div>
      <div className="card" style={{ backgroundColor: "white", width: "100%", boxShadow: "none", border: "none",margin:"0px 0px"}}>
        <div className="new" data-aos="fade-up">
          <div className="main_review_card  mt-20">
            
              <div className="d-flex flex-row justify-content-center align-items-center flex-wrap gap-5">
              {data.map((d,i) => (
                <div key={d.review_id} className="d-flex review_card bg-white h-[500px] text-black rounded-xl">
                  <div className="flex flex-col items-center justify-center gap-4 p-4">
                    <h6 className="text-xl font-semibold">~ {d.firstName} {d.lastName}</h6>
                    <p className="text-center">{d.review}</p>
                  </div>
                </div>
              ))}
             
              </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
    </div>
  );
}

export default NewSlider;
