import React, { useState, useEffect } from 'react';
import '../AddProduct/addproduct.css';
import axios from 'axios';

function Counter() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/add_visit`
                );
                if (response.status === 200) {
                    setCount(response.data.count);
                    console.log("Visit Count:", response.data.count);
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="container">
                <div className="add_product_container">
                    <div className="register_head mb-3">
                        <h2>Total Visits</h2>
                        <br></br>
                        <div className="upload-square">
                            <h4>Visits: {count}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Counter;
