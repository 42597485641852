import React from 'react';
import { Card, Button }
    from 'react-bootstrap';
import '../AddCategory/addcategory.css'
import product_1 from '../Photos/product_1.png'
import { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

const AddHeroSection = () => {

    const [item, setItem] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {


            // console.log("Data aya  : ", process.env.REACT_APP_BASE_URL)


            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_category_data`
                );
                if (res.status === 200) {
                    setItem(res.data);
                }
            } catch (error) {
                // console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();

    }, []);

    const handlenavigate = async () => {
        navigate('/product_list')
    }
    return (



        <>

            {/* <Card style={{ backgroundColor: 'rgb(219 255 231)', width: '400px', height: '200px' }}>
                <div className="row d-flex align-items-center">
                    <div className="col-md-6 d-flex flex-column justify-content-between align-items-center">
                        <div className='d-flex flex-column justify-content-between align-items-center'>
                            <h5 className="mt-3">Product Name</h5>
                            <Button variant="primary">Buy Now</Button>
                        </div>

                    </div>

                    <img
                        src="https://img.freepik.com/premium-psd/cosmetic-product_986960-1496.jpg"
                        alt="Product"
                        style={{ width: '150px', transition: 'transform 0.5s' }}
                        className="img-fluid"
                        onMouseOver={(e) => (e.target.style.transform = 'translateX(-10px)')}
                        onMouseOut={(e) => (e.target.style.transform = 'translateX(0px)')}
                    />

                </div>


            </Card> */}


            <div className="category_head">
                <h3>Category</h3>
                <p>Select the Category to see Products...</p>
            </div>
            <div className="hover_card">
                <div className="card__container">
                    {item.map((data, i) => (
                        <article className="card__article">
                            <img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.cat_img}`} alt="image" className="card__img" />

                            <div className="card__data">
                                <span className="card__description"></span>
                                <h2>{data.name}</h2>
                                <button href="#" className=" btn btn-success" onClick={handlenavigate}>Shop</button>
                            </div>
                        </article>
                    ))}



                </div>
            </div>

            {/* <div className="category_list">
                {
                    item.map((data,i)=>(
                        <div className="category_main">
                        <div className="main_content">
                            <div className="main_text">
                                <h4>{data.name}</h4>
                                <button className='btn btn-outline-success btn-sm'>Shop</button>
                            </div>
                            <div className="img_content">
                            <img src={`http://localhost:8000/view_doc/${data.cat_img}`}/>
                            </div>
                        </div>
                    </div>
                    ))
                }
          

           
            </div> */}


        </>


    );
};

export default AddHeroSection;
