import React from 'react'
import NavBar from '../NavBar/NavBar'
import { Routes, Route } from 'react-router-dom'
import HeroSection from '../HeroSection/HeroSection'
import Register from '../Register/Register'
import CategoryData from '../Category/CategoryData'
import MyCart from '../MyCart/MyCart'
import CheckOut from '../Checkout/CheckOut'
import { Modal } from 'react-bootstrap'
import ConfirmOrder from '../ConfirmedOrder/ConfirmOrder'
import Product from '../MainProduct/Product'
import DirectCheckout from '../Checkout/DirectCheckout'
import Profile from '../Profile/Profile'
import MyOrder from '../Profile/MyOrder'
import PersonalDetails from '../Profile/PersonalDetails'
import { MainProducts } from '../ProductCart/MainProducts'
import { ProductList } from '../ProductCart/ProductList'
import PageNotFound from '../PageNotFound/PageNotFound'
import YouTube from '../YouTubeVideo/YouTube'
import AdminBill from '../ViewBill/AdminBill'

import Bill from '../Profile/Bill'
const UserDashBoard = ({handleUserLogin,handleLogout ,token,user_id}) => {
    return (
        <>

            <NavBar handleUserLogin={handleUserLogin} handleLogout={handleLogout} user_id={user_id} token={token} />

            <Routes>
                <Route path='/' element={<HeroSection user_id={user_id}/>} />
                <Route path='/category' element={<CategoryData />} />
                <Route path='/mycart' element={<MyCart />} />
                <Route path='/checkout' element={<CheckOut/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/place_order'element={<ConfirmOrder/>}/>
                <Route path='/login' element={<Modal/>}/>
                <Route path='/profile' element={<Profile uid={user_id}/>}/>
                <Route path='/product_list' element={<MainProducts user_id={user_id}/>}/>
                <Route path='/main_product' element={<Product/>}/>
                <Route path='/direct_checkout' element={<DirectCheckout/>}/>
                <Route path='/placed_order' element={<ConfirmOrder/>}/>
                <Route path='/myorders' element={<MyOrder/>}/>
                <Route path='/profile/personal_details'element={<PersonalDetails/>}/>
                <Route path='/download_invoice' element={<Bill/>}/>
                <Route path='/bill_by_admin' element={<AdminBill/>}/>
                <Route path='/*' element={<PageNotFound/>}/>
               

            </Routes>
        </>
    )
}

export default UserDashBoard
