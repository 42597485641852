import React, { useState, useEffect } from 'react';
import '../AddProduct/addproduct.css';
import axios from 'axios';
import background from '../Photos/wave.svg'
import { useLocation } from 'react-router-dom';
import { Toaster, toast } from 'sonner'
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';
import { BsPlusCircle, BsXCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';


const EditProduct = () => {

    const [category, setCategory] = useState([]);
    const [mainproduct, setMainProduct] = useState([]);

    const location = useLocation();

    const product_data = location.state?.product_data || [];
    // console.log(product_data)


    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_product_details/${product_data}`);
                if (res.status === 200) {
                    const mainInfo = res.data[0];

                    // console.log("mainInfo:", mainInfo);

                    setFormData({
                        productName: mainInfo.product_name || '',
                        category: mainInfo.product_category || '',
                        size: mainInfo.product_size || '',
                        price: mainInfo.product_price || '',
                        quantity: mainInfo.product_quantity || '',
                        description: mainInfo.product_desc || '',
                        discount: mainInfo.product_discount,
                        image: mainInfo.product_img
                    });
                    // console.log("New form Data:", formData)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        if (product_data) {
            fetchInfo();
        }
    }, [product_data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_category_data`
                );
                if (res.status === 200) {
                    // Assuming res.data is an array of objects
                    setCategory(res.data);
                }
            } catch (error) {
                // console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();
    }, []);




    const [formData, setFormData] = useState({
        productName: '',
        category: '',
        size: '',
        price: '',
        quantity: '',
        description: '',
        images: '',
        discount: ''
    });

    const formDataToSend2 = new FormData();

    const handleClickUpload = (index) => {
        const fileInput = document.getElementById(`file-upload-${index}`);
        fileInput.click();
    };
    const handleCancelImage = () => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [],
        }));
    };
    const handleImageUpload = async (e, index) => {
        const files = e.target.files; // Get all selected files
        // console.log("Image for updation:", files)
        if (files.length > 0) {
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('image', files[0]);
                // console.log("Image for updation:", formDataToSend)
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/upload_image/${product_data}`,
                    formDataToSend
                );

                if (response.status === 200) {
                    toast.success("Image Updated Successfully!")
                    window.location.reload();
                } else {
                    // console.error('Image upload failed:', response.data.message);
                }
            } catch (error) {
                // console.error('Error uploading image:', error.message);
            }
        }
    };





    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeImage = (filelist) => {
        // console.log("Images aayi : ", filelist);

        const updatedFormData = { ...formData }; // Create a copy of formData

        for (let index = 0; index < filelist.length; index++) {
            const file = filelist[index];
            updatedFormData.images.push(file); // Push the file to the images array in updatedFormData
        }

        // Update the formData state with the updatedFormData
        setFormData(updatedFormData);

        // console.log("Form data with images:", updatedFormData);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('productName', formData.productName);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('size', formData.size);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('quantity', formData.quantity);
        formDataToSend.append('discount', formData.discount);
        formDataToSend.append('description', formData.description);

        // console.log("New  List : ", formData);

        // for (let index = 0; index < formData.images.length; index++) {
        //     const file = formData.images[index];
        //     formDataToSend.append('images', file);
        // }

        // console.log("actual img:", formDataToSend);

        try {
            const product_id = product_data;
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/update_product/${product_id}`,
                formData
            );
            if (response.status == 200) {
                toast.success("Edited Successfully")
            }
            window.open('/inventory', '_self')
            // console.log(response.data);
        } 
        catch (error) {
            // console.error("Error:", error);
        }
    };



    return (
        <div className="add_product mb-2" style={{ marginTop: '3%' }}>
          
            <Toaster position="top-center" richColors />

            <div className="container">
            <div className="button mb-3">
                <Link to='/inventory'><button className='btn btn-success'><FaArrowLeft/>Back</button></Link>
            </div>
                <div className="add_product_container">
                    <div className="register_head mb-3">
                        <h2>Edit Product</h2>
                        <p>Upload all the necessary info..</p>
                    </div>

                    <form className="row g-3" onSubmit={handleSubmit}>

                        {/* Preview area for selected images */}
                        <div className="d-flex justify-content-center align-items-center flex-wrap gap-5">

                            

                            <div className="col-md-3">
                                <div className="upload-square uploaded_square_img">
                                    <img className='col-md-3' src={`${process.env.REACT_APP_BASE_URL}/view_doc/${formData.image}`} alt="..." />

                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="upload-square">
                                    <div className="upload-icon">
                                        <BsPlusCircle onClick={() => handleClickUpload(1)} />
                                        <input
                                            id={`file-upload-1`}
                                            type="file"
                                            accept="image/*"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                handleImageUpload(e, 1);
                                            }}
                                        />
                                    </div>
                                    <p className="upload-text">Upload New Image</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="productName" className="form-label">Product Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="productName"
                                name="productName"
                                value={formData.productName}
                                onChange={handleChange}
                            // style={{boxShadow:'4px 4px 4px rgba(0, 0, 0, 0.19)'}}
                            />
                        </div>



                        <div className="col-md-6">
                            <label htmlFor="category" className="form-label">Category</label>

                            <select class="form-select" aria-label="Default select example" id="category" name="category" value={formData.category} onChange={handleChange}>
                                <option defaultChecked={true}>--Select Category--</option>
                                {category.map((data, i) => (
                                    <>
                                        <option value={data.ID} key={i}>{data.name}</option>
                                    </>
                                ))}
                            </select>

                        </div>

                        <div className="col-md-4">
                            <label htmlFor="size" className="form-label">Size</label>
                            <select class="form-select" aria-label="Default select example" id="size" name="size" value={formData.size} onChange={handleChange}>
                                <option defaultChecked={true}>--Select Size--</option>
                                <option value={'50ml'}>50ml</option>
                                <option value={'100ml'}>100ml</option>
                                <option value={'150ml'}>150ml</option>
                                <option value={'250ml'}>250ml</option>
                                <option value={'500ml'}>500ml</option>
                                <option value={'1ltr'}>1ltr</option>
                                <option value={'2ltr'}>2ltr</option>
                                <option value={'1 Box(12 Mangoes)'}>1 Box(12 Mangoes)</option>
                                <option value={'1 Box(15 Mangoes)'}>1 Box(15 Mangoes)</option>
                            </select>
                            {/* <input
                            type="text"
                            className="form-control"
                          
                            value={formData.size}

                        /> */}
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="prices" className="form-label">Price</label>
                            <input
                                type="number"
                                className="form-control"
                                id="prices"
                                name="price"
                                value={formData.price}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="quantity" className="form-label">Quantity</label>
                            <input
                                type="number"
                                className="form-control"
                                id="quantity"
                                name="quantity"
                                value={formData.quantity}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="discount" className="form-label">Discount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="discount"
                                name="discount"
                                value={formData.discount}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-12">
                            <label htmlFor="quantity" className="form-label">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-center">
                            <button type="submit" className="btn btn-success col-12" style={{ backgroundColor: '#095809' }}>Add Product</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
};

export default EditProduct;
