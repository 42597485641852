import { React, useState, useEffect } from 'react'
import '../Register/register.css'
import './adminreview.css'
import axios from 'axios'
import { Toaster, toast } from 'sonner'
import { useNavigate } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";

const AdminReview = () => {


    const navigate = useNavigate();

    const [formData, setFormData] = useState({
      username:'',
        review: '',
        rating:'',
    });

   

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = async (e) => {
        console.log("Review Data : ",formData)
        e.preventDefault();

        if (!formData.username || ! formData.review || !formData.rating) {
            alert('All fields are reqired!');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/admin_review`, formData);
            console.log('Form submitted successfully:', response.data);

            if (response.data.success === false) {
                toast.warning(response.data.message)
            }
            else {
                toast.success('Review Added Successfully!')
                setTimeout(() => {
                    navigate('/');
                }, 2500);
            }


        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle form submission error (e.g., display error message to the user)
        }
    };

    const handleLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setFormData(prevState => ({
                        ...prevState,
                        latitude: latitude,
                        longitude: longitude,
                    }));
                },
                error => {
                    console.error('Error getting location:', error);
                    // Handle location retrieval error (e.g., display an error message)
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            // Handle geolocation not supported error (e.g., display an error message)
        }
    };

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setFormData(prevState => ({
            ...prevState,
            rating: newRating,
        }));
    };
    

    return (
        <>

            <div className="container">
                <div className="main_review_container">
                <div className="add_review_container add_product_container">
                <Toaster position="top-center" richColors />
                <div className="register_head mb-3">
                    <h2>Add Reviews</h2>
                    <p></p>
                </div>

                <form className="row g-3" onSubmit={handleSubmit}>
                    <div className="col-md-12">
                        <label for="first_name" className="form-label" style={{color:'white'}}>Enter User Name</label>
                        <input type="text" className="form-control" name="username" id="inputFirstName" onChange={handleChange} />
                    </div>
                    
                    <div className="col-md-12">
                        <label for="last_name" className="form-label" style={{color:'white'}}>Give Star Rating</label>
                        <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={30}
                                                        value={1}
                                                        activeColor="#ffd700"
                                                        className='col-md-6'
                                                        />
                    </div>
                    <div className="col-md-12">
                        <label for="last_name" className="form-label" style={{color:'white'}}>Enter Review</label>
                        <textarea type="text" className="form-control" name="review" id="inputLastName" onChange={handleChange} />
                    </div>
                   

               
                    <div className="col-12">
                        <button type="submit" className="btn btn-success">Add Review</button>
                    </div>
                </form>

                </div>
                </div>

            </div>
        </>
    )
}

export default AdminReview
