import React from "react";
import svg from "./404.svg";
import './page.css'
import { FaArrowLeft,FaArrowRight } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {

    const navigate=useNavigate();

    const handlenavigate=()=>{
        navigate('/')
    }
    
    return (
        <>
            <div className="cont-404">
                <img src={svg} alt="svg" />
                <button className="btn btn-success" onClick={handlenavigate}><FaArrowLeft/>  Back to Home</button>
            </div>
        </>
    );
};

export default PageNotFound;
