import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import logo from '../Photos/logo.png';
import axios from 'axios';
import './modal.css'
import { Toaster, toast } from 'sonner';

const LoginModal = ({ isOpen, onClose, handleUserLogin, token }) => {
  const [formData, setFormData] = useState({
    mobileNumber: '',
    otp: '',
    isOTPMode: false, // Flag to indicate OTP verification mode
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    // Check if mobile number is entered
    if (!formData.mobileNumber) {
      toast.error('Please enter a mobile number.');
      return;
    }

    // Send OTP logic
    try {
        setFormData((prevState) => ({
           ...prevState,
              // Switch to OTP verification mode
         }));
      // Call your API endpoint to send OTP
      // Example:
      const phone=formData.mobileNumber;
      console.log(phone)
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/sendOtp`, {phone});

      if (res.data.success) {
        setFormData((prevState) => ({
          ...prevState,
          isOTPMode: true, // Switch to OTP verification mode
        }));
        toast.success('OTP sent successfully.');
      } else {
        toast.error('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error('Please Register First!!');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault()
    // Validate OTP and login logic
    try {
      // Call your API endpoint to verify OTP and login
      // Example:
      console.log("For Verification:",formData)

      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify_otp`, {
        mobileNumber: formData.mobileNumber,
        otp: formData.otp,
      });
      if (res.data.success) {
        handleUserLogin(res.data.token, res.data.uid, res.data.user_type);
        localStorage.setItem('token', res.data.token);
        toast.success('Logged In Successfully');
      } else {
        toast.error('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error logging in with OTP:', error);
      toast.error('Error logging in. Please try again later.');
    }
  };

  const handleToggleMode = () => {
    setFormData((prevState) => ({
      ...prevState,
      isOTPMode: !prevState.isOTPMode, // Toggle OTP verification mode
    }));
  };


  const handleregister=()=>{
    window.open('/register','_self')
  }

  return (
    <>
      <Toaster position="top-center" richColors />
      <Modal show={isOpen} onHide={onClose} centered>
        <Modal.Header closeButton>
          <div className="">
            <img style={{ width: '5rem' }} src={logo} alt="Logo" />
          </div>
        </Modal.Header>
        <Modal.Body>
          {!formData.isOTPMode ? (
            <Form>
              <Form.Group controlId="formBasicMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter mobile number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <br/>
              <button className='btn btn-success btn-sm' onClick={(e)=>handleSendOTP(e)}>
                Get OTP
              </button>
            </Form>
          ) : (
            <Form>
              <Form.Group controlId="formBasicOTP">
                <Form.Label>Enter OTP</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  name="otp"
                  value={formData.otp}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <br/>
              <button className='btn btn-success btn-sm' onClick={handleLogin}>
                Login
              </button>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <p>
            {formData.isOTPMode ? 'Did not receive OTP?' : 'Don\'t have an account?'}
            <span
              style={{ cursor: 'pointer', marginLeft: '5px' }}
              onClick={formData.isOTPMode ? handleSendOTP : handleregister}
            >
              {formData.isOTPMode ? 'Resend OTP' : 'Sign Up'}
            </span>
          </p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginModal;
