import React from 'react';
import './youtube.css'; // Import CSS file for styling

const YouTube = () => {
  return (
    <div className="container">
      <div className="category_head">
        <h3>Check Out Our Videos</h3>
      </div>
      <br/>
      <div className="ratio ratio-16x9">
        <iframe
          style={{borderRadius:'10px',boxShadow:'4px 4px 4px 4px rgb(0,0,0.2)'}}
          src='https://www.youtube.com/embed/0BFxiiwxWCI'
          title='Youtube video'
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default YouTube;
