import React from 'react'
import NavBar from '../NavBar/NavBar'
import { Routes, Route } from 'react-router-dom'
import HeroSection from '../HeroSection/HeroSection'
import Register from '../Register/Register'
import CategoryData from '../Category/CategoryData'
import AdminNavBar from '../NavBar/AdminNavBar'
import AddProduct from '../AddProduct/AddProduct'
import AddCategory from '../AddCategory/AddCategory'
import Product from '../MainProduct/Product'
import CheckOut from '../Checkout/CheckOut'
import DirectCheckout from '../Checkout/DirectCheckout'
import MyCart from '../MyCart/MyCart'
import ConfirmOrder from '../ConfirmedOrder/ConfirmOrder'
import Inventory from '../Inventory/Inventory'
import PlacedOrders from '../PlaceOrders/PlacedOrders'
import Profile from '../Profile/Profile'
import { MainProducts } from '../ProductCart/MainProducts'
import AddHeroSection from '../HeroSection/AddHeroSection'
import EditHeroSection from '../HeroSection/EditHeroSection'
import EditProduct from '../Inventory/EditProduct'
import PageNotFound from '../PageNotFound/PageNotFound'
// import Bill from '../Profile/Bill'
import AdminReview from '../AdminReview/AdminReview'
import PhoneEntry from '../Register/PhoneEntry'
import EditCategory from '../Category/EditCategory'
import ViewBill from '../ViewBill/ViewBill'
import AdminBill from '../ViewBill/AdminBill'
import AdminRegister from '../Register/AdminRegister'

const AdminDashBoard = ({handleUserLogin,handelLogout,token,user_id,role}) => {


    return (
        <>
            <AdminNavBar handleUserLogin={handleUserLogin} handelLogout={handelLogout} token={token} user_id={user_id} role={role} />

            <Routes>
                <Route path='/' element={<HeroSection user_id={user_id} role={role}/>} />
                <Route path='/add_category' element={<AddCategory/>}/>
                <Route path='/category' element={<CategoryData />} />
                <Route path='/mycart' element={<MyCart />} />
                <Route path='/add_product' element={<AddProduct/>}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/main_product' element={<Product/>}/>
                <Route path='/checkout' element={<CheckOut/>}/>
                <Route path='/direct_checkout' element={<DirectCheckout/>}/>
                <Route path='/placed_order' element={<ConfirmOrder/>}/>
                <Route path='/profile' element={<Profile uid={user_id}/>}/>
                <Route path='/inventory' element={<Inventory/>}/>
                <Route path='/product_list' element={<MainProducts user_id={user_id}/>}/>
                <Route path='/placed_orders' element={<PlacedOrders/>}/>
                <Route path='/add_her0section' element={<AddHeroSection/>}/>
                <Route path='/edit_herosection'element={<EditHeroSection/>}/>
                <Route path='/edit_product_details' element={<EditProduct/>}/>
                <Route path='/admin_reviews' element={<AdminReview/>}/>
                <Route path='/view_bills' element={<ViewBill/>}/>
                <Route path='/bill_by_admin' element={<AdminBill/>}/>
                <Route path='/add_user_orders' element={<PhoneEntry/>}/>
                <Route path='/*' element={<PageNotFound/>}/>
                <Route path='/admin_user_register' element={<AdminRegister/>}/>
                <Route path='/edit_catgory'element={<EditCategory/>}/>
            </Routes>
        </>
    )
}

export default AdminDashBoard
