
// import './App.css';
// import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
// import UserDashBoard from './components/UserDashBoard/UserDashBoard';
// import { useEffect, useState } from 'react';
// import AdminDashBoard from './components/AdminDashBoard/AdminDashBoard';

// function App() {



//   const [token, setToken] = useState("");
//   const [role, setRole] = useState("");
//   const [user_id, setUID] = useState(0);


//   const handleLogout = () => {
//     window.open('/','_self');
//     setToken("");
//     window.localStorage.removeItem("token");
//     window.localStorage.removeItem('user_type');
//     window.localStorage.removeItem('uid');
//   };


//   const handleLogin = (newToken, newuid, user_type) => {
//     window.open('/','_self')
//     setToken(newToken);
//     window.localStorage.setItem("user_type", user_type);
//     window.localStorage.setItem("uid", newuid);

//     // console.log("User type ye hai: ", user_type);
//     // console.log("UID ye hai: ", newuid);
//   };

//   useEffect(() => {
//     const storedToken = window.localStorage.getItem("token");
//     if (storedToken) {
//       setToken(storedToken);
//       const storedRole = window.localStorage.getItem("user_type");
//       const uid = window.localStorage.getItem('uid');
//       if (storedRole) {
//         setRole(storedRole);
//         setUID(uid);
//       }
//       // if(fac_id) { setFaculty_ID(fac_id)}
//     }

//     // console.log("Role ye hai:", role);
//     // console.log("User_ID : ", user_id);
//   }, [token, role]);


//   return (
//     <>
//       <Router>

//         {role == 1 ? (
//           <AdminDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} role={role} />

//         ) : (
//           <UserDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} />

//         )}
//       </Router>
//       {/* <CategoryData />
//       <ProductList />
//       <Product />
//       <Register /> */}
//     </>
//   );
// }

// export default App;


import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import UserDashBoard from './components/UserDashBoard/UserDashBoard';
import { useEffect, useState } from 'react';
import AdminDashBoard from './components/AdminDashBoard/AdminDashBoard';
import CryptoJS from 'crypto-js';

const SECRET_KEY = 'your-secret-key';  // Replace with a strong, unique key for encryption

function App() {
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [user_id, setUID] = useState(0);

  const handleLogout = () => {
    window.open('/', '_self');
    setToken("");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem('user_type');
    window.localStorage.removeItem('uid');
  };

  const handleLogin = (newToken, newuid, user_type) => {
    window.open('/', '_self');
    setToken(newToken);

    // Encrypt user_type and newuid before storing them
    const encryptedUserType = CryptoJS.AES.encrypt(user_type.toString(), SECRET_KEY).toString();
    const encryptedUID = CryptoJS.AES.encrypt(newuid.toString(), SECRET_KEY).toString();

    window.localStorage.setItem("user_type", encryptedUserType);
    window.localStorage.setItem("uid", encryptedUID);
  };

  useEffect(() => {
    const storedToken = window.localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);

      const encryptedUserType = window.localStorage.getItem("user_type");
      const encryptedUID = window.localStorage.getItem("uid");

      if (encryptedUserType && encryptedUID) {
        // Decrypt user_type and uid before using them
        const decryptedUserType = CryptoJS.AES.decrypt(encryptedUserType, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        const decryptedUID = CryptoJS.AES.decrypt(encryptedUID, SECRET_KEY).toString(CryptoJS.enc.Utf8);

        setRole(decryptedUserType);
        setUID(decryptedUID);
      }
    }
  }, [token, role]);

  return (
    <>
      <Router>
        {role == 1 ? (
          <AdminDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} role={role} />
        ) : (
          <UserDashBoard handleUserLogin={handleLogin} handleLogout={handleLogout} token={token} user_id={user_id} />
        )}
      </Router>
    </>
  );
}

export default App;
