import React, { useState, useEffect } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import './addproduct.css';
import axios from 'axios';
import { Toaster, toast } from 'sonner'
import background from '../Photos/wave.svg'

const AddProduct = () => {

    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);


    const [sizeOptions, setSizeOptions] = useState({
        1: ['50ml', '100ml', '250ml', '500ml', '1ltr', '2ltr', '5ltr'],
        3: ['1 Box (12 Mangoes)','1 Box (15 Mangoes)'],
        4: ['250gm','500gm','1kg','1.5kg','2kg','2.5kg','3kg','3.5kg','4kg','4.5kg','5kg','10kg']
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_category_data`
                );
                if (res.status === 200) {
                    // Assuming res.data is an array of objects
                    setCategory(res.data);
                }
            } catch (error) {
                // console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();
    }, []);

    const [formData, setFormData] = useState({
        productName: '',
        category: '',
        size: '',
        price: '',
        quantity: '',
        description: '',
        images: [],
        discount: ''
    });

    const formDataToSend2 = new FormData();

    const handleClickUpload = (index) => {
        const fileInput = document.getElementById(`file-upload-${index}`);
        fileInput.click();
    };

    const handleImageUpload = (e, index) => {
        const files = e.target.files; // Get all selected files
        // console.log('Selected files:', files); // Check if the correct files are selected

        if (files.length > 0) {
            const updatedImageFiles = [...formData.images];

            // Replace the file at the specified index with the selected files
            updatedImageFiles.splice(index, 1, ...files);

            // console.log('Updated files array:', updatedImageFiles); // Check if files array is updated

            setFormData((prevFormData) => ({
                ...prevFormData,
                images: updatedImageFiles,
            }));
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'category') {
            
            const selectedCategorySizes = sizeOptions[value] || [];
            setSizeOptions({ ...sizeOptions, [value]: selectedCategorySizes });
        }
        if(name==='size') {
            // console.log("Size in fromdata: ",formData)
        }

    };

    const handleChangeImage = (filelist) => {

        // console.log("Images aayi : ", filelist);

        const updatedFormData = { ...formData }; // Create a copy of formData

        for (let index = 0; index < filelist.length; index++) {
            const file = filelist[index];
            updatedFormData.images.push(file); // Push the file to the images array in updatedFormData
        }

        // Update the formData state with the updatedFormData
        setFormData(updatedFormData);

        // console.log("Form data with images:", updatedFormData);
  
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('productName', formData.productName);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('size', formData.size);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('quantity', formData.quantity);
        formDataToSend.append('discount', formData.discount);
        formDataToSend.append('description', formData.description);

        // console.log("File List : ", formData.images);
        // console.log("FormData:",formData)


        for (let index = 0; index < formData.images.length; index++) {
            const file = formData.images[index];
            formDataToSend.append('images', file);
        }


        try {
            // console.log("actual img:", formData);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/add_data`,
                formDataToSend
            );
            if (response.data.success === false) {
                toast.warning(response.data.message)
            }
            else if (response.data.success === true) {
                toast.success(response.data.message)
                window.open('/','_self')
            }

        } catch (error) {
            // console.error("Error:", error);
        }
        finally {
            setLoading(false); 
        }
    };




    return (
        <>
         {loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            <div className="add_product mb-2" style={{ marginTop: '5%' }}>
           
           <Toaster position="top-center" richColors />
           {/* <svg id="sw-js-blob-svg" style={{
      width:'20rem',
       position: 'absolute',
       top: -120,
       rotate:30,
       left:-100,
       transform: 'rotate(45deg)',
       right: 0,
       zIndex: 1, // Ensure the SVG is behind other content
       opacity: 1, // Adjust the opacity as needed
   }} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">  <defs>  <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">  <stop id="stop1" stop-color="rgba(248, 117, 55, 1)" offset="0%"></stop> 
   <stop id="stop2" stop-color="rgba(251, 168, 31, 1)" offset="100%"></stop> 
   </linearGradient>   </defs> <path fill="url(#sw-gradient)" d="M21.9,-32.3C28.1,-30.1,32.5,-23.5,33.5,-16.7C34.5,-9.8,32.1,-2.8,30.2,3.7C28.4,10.3,27,16.3,23.4,20.2C19.8,24.1,14,25.9,8.1,27.7C2.2,29.6,-3.8,31.4,-10.7,31.5C-17.6,31.7,-25.5,30.3,-30.4,25.8C-35.2,21.2,-37,13.4,-36.6,6.2C-36.2,-1,-33.6,-7.6,-31.6,-15.4C-29.6,-23.3,-28.1,-32.4,-23,-35C-17.8,-37.6,-8.9,-33.6,-0.5,-32.8C7.9,-32,15.8,-34.4,21.9,-32.3Z" width="100%" height="100%" transform="translate(50 50)" stroke-width="0" style={{ transition: "all 0.3s", ease:"0s"}}></path>              </svg> */}

           <div className="container">

               <div className="add_product_container">
                   <div className="register_head mb-3">
                       <h2>Add Product</h2>
                       <p>Upload all the necessary info..</p>
                   </div>

                   <form className="row g-3" onSubmit={handleSubmit}>
                       <div className="upload_img d-flex justify-content-center">
                           <div className="col-md-3">
                               <div className="upload-square">
                                   <div className="upload-icon">
                                       <BsPlusCircle onClick={() => handleClickUpload(1)} />
                                       <input
                                           id={`file-upload-1`}
                                           type="file"
                                           accept="image/*"
                                           multiple
                                           style={{ display: 'none' }}
                                           onChange={(e) => {
                                               const files = e.target.files;
                                               handleChangeImage(files);
                                           }}
                                       />
                                   </div>
                                   <p className="upload-text">Upload Image</p>
                               </div>
                           </div>

                           {/* Preview area for selected images */}
                           <div className="uploaded_square d-flex justify-content-center">
                               <div className="uploaded_img">
                                   <p>Selected images will appear here...</p>
                                   {formData.images.map((image, index) => (

                                       <img
                                           key={index}
                                           src={URL.createObjectURL(image)}
                                           alt={`Image ${index + 1}`}
                                           className="preview-image"
                                       />

                                   ))}
                               </div>
                           </div>


                       </div>



                       <div className="col-md-6">
                           <label htmlFor="productName" className="form-label">Product Name</label>
                           <input
                               type="text"
                               className="form-control"
                               id="productName"
                               name="productName"
                               value={formData.productName}
                               onChange={handleChange}
                           // style={{boxShadow:'4px 4px 4px rgba(0, 0, 0, 0.19)'}}
                           />
                       </div>
                       <div className="col-md-6">
                           <label htmlFor="category" className="form-label">Category</label>

                           <select class="form-select" aria-label="Default select example" id="category" name="category" onChange={handleChange}>
                               <option defaultChecked={true}>--Select Category--</option>
                               {category.map((data, i) => (
                                   <>
                                       <option value={data.ID} key={i}>{data.name}</option>
                                   </>
                               ))}
                           </select>

                       </div>

                       <div className="col-md-4">
                           <label htmlFor="size" className="form-label">Size</label>

                           <select class="form-select" aria-label="Default select example" id="size" name="size" onChange={handleChange}>
                            <option defaultChecked>--Select Size--</option>
                           {sizeOptions[formData.category]?.map((size, index) => (
                        <option value={size} key={index}>{size}</option>
                    ))}
                           </select>
                           {/* <input
                           type="text"
                           className="form-control"
                         
                           value={formData.size}

                       /> */}
                       </div>

                       <div className="col-md-4">
                           <label htmlFor="prices" className="form-label">Price</label>
                           <input
                               type="text"
                               className="form-control"
                               id="prices"
                               name="price"
                               value={formData.price}
                               onChange={handleChange}
                           />
                       </div>
                       <div className="col-md-4">
                           <label htmlFor="quantity" className="form-label">Quantity</label>
                           <input
                               type="text"
                               className="form-control"
                               id="quantity"
                               name="quantity"
                               value={formData.quantity}
                               onChange={handleChange}
                           />
                       </div>

                       <div className="col-12">
                           <label htmlFor="discount" className="form-label">Discount</label>
                           <input
                               type="number"
                               className="form-control"
                               id="discount"
                               name="discount"
                               value={formData.discount}
                               onChange={handleChange}
                           />
                       </div>

                       <div className="col-12">
                           <label htmlFor="quantity" className="form-label">Description</label>
                           <textarea
                               type="text"
                               className="form-control"
                               id="description"
                               name="description"
                               value={formData.description}
                               onChange={handleChange}
                           />
                       </div>
                       <div className="col-12 mt-3 d-flex justify-content-center">
                           <button type="submit" className="btn btn-success col-12" style={{ backgroundColor: '#095809' }}>Add Product</button>
                       </div>
                   </form>
               </div>

           </div>
       </div>
        </>
       
    );
};

export default AddProduct;
