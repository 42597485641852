import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import logo from '../Photos/logo.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'sonner'

const LoginModal = ({ isOpen, onClose, handleUserLogin, token }) => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const navigate=useNavigate();


  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  };
  const handleLogin = () => {
    // Handle login logic here
    // console.log('Logging in with:', username, password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // console.log(formData);

        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, formData);
        // console.log(res.data);

        if (res.data.success === true) {
            handleUserLogin(res.data.token, res.data.uid, res.data.user_type);
            localStorage.setItem("token", res.data.token);

            // console.log("Token", res.data.token);
            // console.log("User ID", res.data.uid);
            // console.log("User Type", res.data.user_type);

            toast.success("Logged In Successfully");
        } else {
            toast.error('Invalid credentials!');
        }
    } catch (error) {
        // console.error(error);
        toast.error('Error logging in. Please try again later.');
    }
};



  if (token) {
    const user_type = localStorage.getItem("user_type");
    // console.log("Role getItem :",user_type)
    if (user_type === 1) {
        navigate('/');
    }
    else if (user_type == 2) {
        navigate('/');
    }
}

const handleregister=()=>{
  window.open('/register','_self')
}

  return (<>
    <Toaster position="top-center" richColors />
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        {/* <Modal.Title>Welcome</Modal.Title> */}
        <div className=''>
          <img style={{ width: '5rem' }} src={logo} />
        </div>
      </Modal.Header>
      <br />
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicUsername">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              required
            />
            <br />
          </Form.Group>
          <Button variant="success" type="submit" className="mb-3">
            Login
          </Button>
        </Form>
       
      </Modal.Body>
      <Modal.Footer>
        <p>Don't have an account? <a onClick={handleregister} style={{cursor:'pointer'}}>Sign Up</a></p>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default LoginModal;
