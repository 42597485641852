import React, { useState, useEffect } from 'react';
import { FaShoppingCart, FaMapMarkerAlt, FaUserSecret, FaStar, FaQuestionCircle } from 'react-icons/fa';
import './profile.css';
import SideBar from './SideBar';
import Content from './Content';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Profile = ({uid}) => {
    // const { uid } = useParams();
    const [data, setData] = useState([]);
    const [activeOption, setActiveOption] = useState('dashboard');

    const handleOptionClick = (option) => {
        setActiveOption(option);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_user_data/${uid}`);
                setData(res.data);
            } catch (error) {
                // console.log("Error fetching user data:", error);
            }
        };

        fetchData();
    }, [uid]);

    return (
        <div className="container" style={{marginTop:'3%'}}>
            <div className="category_head mt-0">
                <h3>Welcome User</h3>
            </div>
            <hr />

            <div className="user_info d-flex">
                <div>
                <SideBar activeOption={activeOption} handleOptionClick={handleOptionClick} />
                </div>
                <div className="content">
                    <Content activeOption={activeOption} data={data} handleOptionClick={handleOptionClick} uid={uid} />
                </div>
            </div>
        </div>
    );
};

export default Profile;
