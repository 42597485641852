import React from 'react'
import Profile from './Profile'
import SideBar from './SideBar'


const PersonalDetails = () => {
    return (
        <>
         
            <div>
                Hello
            </div>
        </>
    )
}

export default PersonalDetails
