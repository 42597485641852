import React from 'react';
import './profile.css'
const SideBar = ({ activeOption, handleOptionClick }) => {

    return (

        <div className="sidebar">
        <div className="d-flex flex-column flex-shrink-0 p-3" style={{ width: '280px', boxShadow: '4px 4px 4px 4px rgba(0, 0, 0, 0.208)' }}>
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <svg className="bi pe-none me-2 text-dark" width="40" height="32"><use xlinkHref="#bootstrap"></use></svg>
                <span className="fs-4 text-dark">My Profile</span>
            </a>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <a href="#" className={`nav-link ${activeOption === 'dashboard' ? 'active' : ''}`} onClick={() => handleOptionClick('dashboard')} aria-current="page">
                        <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#dashboard"></use></svg>
                        DashBoard
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#" className={`nav-link ${activeOption === 'personal_details' ? 'active' : ''}`} onClick={() => handleOptionClick('personal_details')}>
                        <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#table"></use></svg>
                        Personal Details
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#" className={`nav-link ${activeOption === 'orders' ? 'active' : ''}`} onClick={() => handleOptionClick('orders')}>
                        <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#table"></use></svg>
                        Orders
                    </a>
                </li>
                <li className="nav-item">
                    <a href="#" className={`nav-link  ${activeOption === 'address' ? 'active' : ''}`} onClick={() => handleOptionClick('address')}>
                        <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#grid"></use></svg>
                       Address
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a href="#" className={`nav-link  ${activeOption === 'reviews' ? 'active' : ''}`} onClick={() => handleOptionClick('reviews')}>
                        <svg className="bi pe-none me-2" width="16" height="16"><use xlinkHref="#people-circle"></use></svg>
                        Reviews
                    </a>
                </li> */}
            </ul>
            <hr />
        </div>
        </div>
    );
};

export default SideBar;
