import React from 'react'
import './checkout.css'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'sonner'

const DirectCheckout = () => {


    const location = useLocation();
    const [isEditMode, setIsEditMode] = useState(false);
    const id = location.state?.user_id || [];
    const [isAddressSaved, setIsAddressSaved] = useState(false);
    const [showdelivery, setShowDelivery] = useState(false);

    const quantity = location.state?.quantity || [];
    const total_price = location.state?.total_price || [];
    const product_id = location.state?.product_id || [];
    // console.log("ID mila : ", id, "Product ID : ", product_id, "Quant:", quantity, "Total Price:", total_price)
    const cartIds = location.state?.cartIds || []
    const productData = location.state?.productdata || []
    // console.log("Product Data mila : ", productData)
    const [data, setData] = useState([]);
    const [email, setEmail] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_product_details/${product_id}`
                );
                // console.log("Data we get is :", res.data);
                setData(res.data)
                calculateTotalPrice(res.data)
                if (res.status === 200) {

                    // Assuming res.data is an object with a key 'count'

                }
            } catch (error) {
                // console.error("Error assigning subject data:", error.message);
            }
        };

        fetchData();





    }, [product_id]);

    const [totalPriceOverall, setTotalPriceOverall] = useState(0);

    const [statedata, setStateData] = useState([

        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ])


    const [addressData, setAddressData] = useState({
        address: '',
        landmark: '',
        city: '',
        state: '',
        street: '',
        pincode: '',
        id: id,
        cartIds: cartIds,
        cartData: product_id,
        total_price: total_price,
        quantity: quantity,
        product_id: product_id
    });

    const [isaddress, setIsAddress] = useState(false)
    const [addressinfo, setAddressInfo] = useState([])


    const calculateTotalPrice = (cartItems) => {
        let totalPrice = 0;

        cartItems.map((item) => {
            totalPrice = item.after_discount_price;
        });

        if (totalPrice < 500) {
            setShowDelivery(true);
            totalPrice = totalPrice + 100;
        }

        setTotalPriceOverall(totalPrice);
    };

    useEffect(() => {
        const fetchData = async () => {
            const uid = id;

            // console.log("uid is here : ", id)
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_user_data/${uid}`);
                // console.log("Email Mila : ", res.data)
                if (res.status === 200) {
                    setEmail(res.data[0].email);
                    // console.log("Good Morning Email:", email)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };


        fetchData();
    }, [id]);

    const handleSubmitAddress = async (e) => {
        e.preventDefault();
        setIsAddress(true);
        // console.log("Address Submitted!", addressData);
     

        try {
            if (!addressData.id || addressData.id.length === 0) {
                // Alert or notify the user that the ID array is missing or empty
                toast.warning('Please Login first.');
                return; // Exit the function if the ID array is missing or empty
            }
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/confirm_order_direct`, addressData);
            console.log('Order placed:', response.data);
            if (response.data.success === false) {
                toast.warning(response.data.message);
            } else {
                toast.success(response.data.message);
                setIsAddressSaved(true);
                // window.location.reload()
            }

        } catch (error) {
            console.error('Error placing order:', error);
            // Handle error (e.g., show error message to the user)
        }

    }
    const handlePlaceOrder = async (e) => {
        e.preventDefault();

        if (!isAddressSaved) {
            alert("Please save your address before placing the order.");
            return;
        }

        try {
            

            if (!addressData.id || addressData.id.length === 0) {
                // Alert or notify the user that the ID array is missing or empty
                alert('Please Login first.');
                return; // Exit the function if the ID array is missing or empty
            }
          
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/place_order_direct`, addressData);
            // console.log('Order placed:', response.data);
            toast.success("Order Placed Successfully!")
            try {
                // console.log("Email Sathi:", productData)
                const emailData = {
                    to: email,
                    subject: 'Test Email',
                    text: 'This is a test email sent from my React app.',
                    productData: productData,
                };

                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/send_email`, emailData);
                // console.log('Email sent:', response.data);
            } catch (error) {
                // console.error('Error sending email:', error);
            }
            navigate('/placed_order', { state: { user_id: id, product_data: productData } })
            // Swal.fire({
            //     title: "Order Purchased",
            //     icon: "success"
            // })
            // Optionally, you can navigate to a success page or show a success message here



        } catch (error) {
            // console.error('Error placing order:', error);
            // Handle error (e.g., show error message to the user)
        }
    }
    // useEffect(() => {

    //     const fetchData = async () => {
    //         try {
    //             const res = await axios.get(
    //                 `${process.env.REACT_APP_BASE_URL}/get_add_cart_products/${id}`
    //             );
    //             // console.log("count bacchi", res);
    //             if (data.length > 0) {
    //                 const { quantity, product_id } = data[0]; // Assuming you want data from the first item
    //                 setAddressData({
    //                     quantity,
    //                     product_id,

    //                 });
    //             }
    //             if (res.status === 200) {
    //                 // Assuming res.data is an object with a key 'count'
    //                 setData(res.data);
    //                 calculateTotalPrice(res.data);
    //                 // console.log("Cart ka data: ", res.data);
    //             }
    //         } catch (error) {
    //             // console.error("Error assigning subject data:", error.message);
    //         }
    //     };



    //     fetchData();




    // }, [id]);

    useEffect(() => {

        const fetchAddress = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/get_address_saved/${id}`,
                    addressData
                );
                // console.log("Saved Address", res.data);
                setAddressInfo(res.data);
                if(res.data.length>0){
                    setIsAddressSaved(true)
                }
            } catch (error) {
                // console.error("Error fetching address:", error.message);
                // Handle error (e.g., show error message to the user)
            }
        };

        fetchAddress()

    }, [id])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddressData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleEdit = () => {
        setIsEditMode(true);
        setAddressData({
            address: addressinfo[0]?.address1 || '',
            landmark: addressinfo[0]?.landmark || '',
            city: addressinfo[0]?.city || '',
            state: addressinfo[0]?.state || '',
            street: addressinfo[0]?.street_add || '',
            pincode: addressinfo[0]?.pincode || '',
            id: id,
            product_id: product_id || 0,
            total_price: total_price||0,
            quantity:quantity
        });
    };

    return (
        <>

            <div className="container" style={{ marginTop: '0%' }}>
                <Toaster position="top-center" richColors />
                <div className="checkout_container">
                    <div className="checkout_header">
                        <h3>Checkout</h3>
                    </div>
                    <div className="checkout_main">
                        <div className="left_content">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                <strong>Product Details</strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope='col'></th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Size</th>
                                                                <th scope="col">QTY</th>
                                                                <th scope="col">Price</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.map((item, i) => (
                                                                <tr key={i}>
                                                                   
                                                                    <td id="img_summary"><img src={`${process.env.REACT_APP_BASE_URL}/view_doc/${item.product_img}`} style={{ maxWidth: '7rem', boxShadow: '4px 4px 4px #00000061' }} /></td>
                                                                    <td>{item.product_name}</td>
                                                                    <td>{item.product_size}</td>
                                                                    <td>{quantity}</td>
                                                                    <td>₹ {total_price}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                <strong>Select Delivery Address</strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                            <div className="accordion-body">

                                                {!isEditMode && addressinfo.length > 0 ? (
                                                    <>
                                                        <div className="info">
                                                            <h7><b>Address : </b></h7>
                                                            {addressinfo.map((data, i) => (
                                                                <p>{data.address1}, {data.street_add}, {data.landmark}
                                                                    <br />{data.city} {data.state} {data.pincode}</p>
                                                            ))}
                                                            <button className='btn btn-danger btn-sm' onClick={handleEdit}>Edit</button>

                                                        </div>
                                                    </>
                                                ) : (
                                                    <form class="row g-3">

                                                        <div class="col-12">
                                                            <label for="inputAddress" class="form-label">Address</label>
                                                            <input type="text" class="form-control" id="inputAddress" name="address" value={addressData.address} onChange={handleInputChange} placeholder="1234 Main St" />
                                                        </div>

                                                        <div class="col-md-6">
                                                            <label for="inputAddress2" class="form-label">Landmark/Area</label>
                                                            <input type="text" class="form-control" id="inputAddress2" name='landmark' value={addressData.landmark} onChange={handleInputChange} placeholder="eg: Near XYZ Hospital" />
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="inputAddress2" class="form-label">Street Name</label>
                                                            <input type="text" class="form-control" id="inputAddress2" name='street' value={addressData.street} onChange={handleInputChange} placeholder="eg: ABC Road" />
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="inputCity" class="form-label">City</label>
                                                            <input type="text" class="form-control" name="city" value={addressData.city} onChange={handleInputChange} id="inputCity" />
                                                        </div>
                                                        <div class="col-md-4">
                                                            <label for="inputState" class="form-label">State</label>
                                                            <select id="inputState" class="form-select" name='state' value={addressData.state} onChange={handleInputChange}>
                                                                {statedata.map((data, i) => (
                                                                    <option key={i} value={data}>{data}</option>
                                                                ))}
                                                                <option selected>Choose...</option>

                                                            </select>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <label for="inputZip" class="form-label">PinCode</label>
                                                            <input type="text" class="form-control" name='pincode' value={addressData.pincode} onChange={handleInputChange} id="inputZip" />
                                                        </div>

                                                        <div class="col-12">
                                                            <button type="submit" class="btn btn-success" onClick={(e) => handleSubmitAddress(e)}>Save Address</button>
                                                        </div>
                                                    </form>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className={`accordion-button ${isaddress ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded={isaddress ? 'true' : 'false'} aria-controls="panelsStayOpen-collapseTwo">
                                                <strong>Paymant Details</strong>
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse ${isaddress ? 'show' : ''}`}>
                                            <div className="accordion-body">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                    <label class="form-check-label" for="flexCheckChecked">
                                                        Cash On Delivery
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="right_content">
                            <div className="content">
                                <h4>Order Summary</h4>
                                <hr />
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Quant.</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((data, i) => (
                                            <>
                                                <tr key={i}>
                                                    <th scope="row">{i + 1}</th>
                                                    <td>{data.product_name}</td>
                                                    <td>{quantity}</td>
                                                    <td>₹ {total_price}/-</td>
                                                </tr>

                                            </>
                                        ))}
                                    

                                    </tbody>
                                </table>
                                {showdelivery ? (
                                    <>
                                        <td></td><td></td><td></td>
                                        <h6 className='text-end'>Delivery Charges : ₹ 100/-</h6>

                                    </>
                                ) : (
                                    <></>
                                )}
                                      
                                        <h6 className=' text-end mt-3' style={{ color: 'green' }}>Total : ₹{totalPriceOverall}</h6>
                                        <hr />
                                <div className="text-center">
                                <button className='btn btn-success btn-md' onClick={handlePlaceOrder}>Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='notice'>
                    <h7>Return Policy</h7>
                    <hr />
                    <p>
                        Need help? Check our help pages or contact us
                        When your order is placed, we'll send you an e-mail message acknowledging receipt of your order. If you choose to pay using an electronic payment method (credit card, debit card or net banking), you will be directed to your bank's website to complete your payment. Your contract to purchase an item will not be complete until we receive your electronic payment and dispatch your item. If you choose to pay using Pay on Delivery (POD), you can pay using cash/card/net banking when you receive your item.</p>
                </div>
            </div>

        </>
    )
}

export default DirectCheckout
